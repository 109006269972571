import React from 'react';
import styled from 'styled-components';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { TextAndImage } from '@beelineloans/cx-library/src/components/layout/panels';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import { Breakpoint, Legal, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { loginEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import { Button } from '@beelineloans/cx-library/src/components/buttons';
import Beach from '../../../shared/images/beach.png';

const LegalWrap = styled(Container)`
  .container-inner {
    padding-top: 0;
  }
`;

const Main = styled(TextAndImage)`
  .container-inner {
    padding-bottom: 75px;
  }
`;

const LegalText = styled(Legal)`
  padding-top: 30px;
`;

const ButtonWrap = styled.div`
  @media only screen and (${props => props.theme.mediaBreakpoints.small}) {
    text-align: center
  }
`;

const HeadlineSpan = styled.span`
  padding-right:20px;
  display: block;
`;

const headlineBlock = (
  <Breakpoint
    smallBreakpoint={(
      <>
        Get your ‘Summer Rate Cooler.’<br /><br />
        Lock then chill out —<br />even if rates go down.
      </>
    )}
    largeBreakpoint={(
      <HeadlineSpan>
        Get your ‘Summer Rate Cooler.’<br /><br />
        Lock then chill out — even if rates go down.
      </HeadlineSpan>
    )}
  />
);

const SummerRateCooler = () => (
  <Template headline={headlineBlock}>
    <SEO
      path="/summer-rate-cooler/"
      title="Get your ‘Summer Rate Cooler.’"
      description="Lock then chill out — even if rates go down."
    />
    <Main
      heading="In two minds about whether to lock your rate?"
      image={{
        src: Beach,
        mobileWidth: '315px',
        desktopWidth: '406px'
      }}
    >
      <Paragraph>
        What if you don’t lock, then rates go up?  You’ll kick yourself.
      </Paragraph>
      <Paragraph>
        But what if you do lock and they go down? And you feel like a chump?
      </Paragraph>
      <Paragraph>
        No one wants to be a chump. A sweaty summer chump.
      </Paragraph>
      <Paragraph>
        Our ‘Summer Rate Cooler’ let’s you have it both ways.
      </Paragraph>
      <Paragraph>
        If you lock, but rates go down by more than 0.25%, reach out to your Loan Guide and they’ll drop the rate you locked by 0.125% — anytime before August 26 2021.
      </Paragraph>
      <Paragraph>
        Even if you’re well on your way to closing, you can still drop it down right up to 10 days before your closing date,
        (as long as you ask us to drop it before August 26 2021).
      </Paragraph>
      <Paragraph>
        So lock your ‘Summer Cooler’ rate in, then sink deep into your hammock, knowing you’re cool both ways, instead of sweating over rate movements — like a chump.
      </Paragraph>
    </Main>
    <LegalWrap>
      <ButtonWrap>
        <Button to={loginEndpoint}>Get my summer rate cooler</Button>
      </ButtonWrap>
      <LegalText>
        *To get the Beeline Summer Cooler you first need to lock your rate with us, then rates have to be 0.25% lower than your locked rate anytime up until August 26 2021.
        Then, if you ask your Loan Guide to drop your rate for you before then, they’ll drop it 0.125% below your locked rate.
      </LegalText>
    </LegalWrap>
  </Template>
);

export default SummerRateCooler;
